import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import Chats from '../../pages/Exhibitor/Chats';
import Speaker from '../Speaker';
import OtherSpeaker from '../OtherSpeaker';
import SponsorsOrganization from '../SponsorsOrganization';
import ListKeys from '../../pages/Exhibitor/ListKeys';

const ScientificProgramming = React.lazy(() => import('../ScientificProgramming'));
const Orientation = React.lazy(() => import('../Orientation'));
const Orientation2 = React.lazy(() => import('../Orientation2'));
const PresentationScientificWork = React.lazy(() => import('../PresentationScientificWork'));
const Presentation = React.lazy(() => import('../Presentation'));
const VirtualLibrary = React.lazy(() => import('../VirtualLibrary'));
const Certificate = React.lazy(() => import('../Certificate'));
const App = React.lazy(() => import('../App'));
const Faq = React.lazy(() => import('../Faq'));
const Lgpd = React.lazy(() => import('../Lgpd'));
const ExhibitorSpace = React.lazy(() => import('../ExhibitorSpace'));
const Lesson = React.lazy(() => import('../Lesson'));
const Booth = React.lazy(() => import('../Booth'));

export default function Modals() {

    const dispatch = useDispatch();
    const [modal, setModal] = useState(null);
    const [chat, setChat] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const pathModal = ['orientation2', 'app', 'lgpd', 'lesson', 'exhibitor-space', 'faq', 'certificate', 'virtual-library', 'others-speaker', 'speaker', 'sponsors-organization', 'scientific-programming', 'orientation', 'presentation-scientific-work', 'presentation', 'booth'];

    // Abre Modal a partir do Menu
    const modalSelected = useSelector(state => state.modals.modal);
    const idSelected = useSelector(state => state.modals.id);
    const eixoId = useSelector(state => sessionStorage.getItem('eixoId'));
    const salaIndex = useSelector(state => sessionStorage.getItem('salaIndex'));

    useEffect(() => {
        if (pathModal.includes(modalSelected)) {
            setShowModal(true);
            setModal(modalSelected);
        }
        if (modalSelected === 'booth') {
            ListKeys.filter(item => {
                if (item.id === idSelected) {
                    setChat(item.key)
                    return item;
                }
                return {};
            });
        }
    }, [modalSelected, pathModal, showModal]);

    function closeModal() {
        if (window.zE) {
            window.zE('webWidget', 'logout');
            window.zE('webWidget', 'hide');
            window.zEACLoaded = undefined;
            setChat(null);
            window.location = window.location;
        }
        dispatch({ type: 'SET_MODAL', modal: '' });
        dispatch({ type: 'SET_ID', modal: '' });

        setShowModal(false);
    }

    return (
        <div>
            {modal === 'exhibitor-space' ? <ExhibitorSpace showModal={showModal} setShowModal={closeModal} /> : ''}
            {modal === 'faq' ? <Faq showModal={showModal} setShowModal={closeModal} /> : ''}
            {modal === 'lgpd' ? <Lgpd showModal={showModal} setShowModal={closeModal} /> : ''}
            {modal === 'speaker' ? <Speaker showModal={showModal} setShowModal={closeModal} /> : ''}
            {modal === 'others-speaker' ? <OtherSpeaker showModal={showModal} setShowModal={closeModal} /> : ''}
            {modal === 'sponsors-organization' ? <SponsorsOrganization showModal={showModal} setShowModal={closeModal} /> : ''}
            {modal === 'presentation' ? <Presentation showModal={showModal} setShowModal={closeModal} /> : ''}
            {modal === 'scientific-programming' ? <ScientificProgramming showModal={showModal} setShowModal={closeModal} eixoId={eixoId} salaIndex={salaIndex} /> : ''}
            {modal === 'lesson' ? <Lesson showModal={showModal} setShowModal={closeModal} /> : ''}
            {modal === 'orientation' ? <Orientation showModal={showModal} setShowModal={closeModal} /> : ''}
            {modal === 'orientation2' ? <Orientation2 showModal={showModal} setShowModal={closeModal} /> : ''}
            {modal === 'presentation-scientific-work' ? <PresentationScientificWork showModal={showModal} setShowModal={closeModal} /> : ''}
            {modal === 'virtual-library' ? <VirtualLibrary showModal={showModal} setShowModal={closeModal} /> : ''}
            {modal === 'certificate' ? <Certificate showModal={showModal} setShowModal={closeModal} /> : ''}
            {modal === 'app' ? <App showModal={showModal} setShowModal={closeModal} /> : ''}
            {modal === 'booth' ? <><Chats chat={chat} /><Booth showModal={showModal} setShowModal={closeModal} id={idSelected} /> </> : ''}
        </div>
    );
}