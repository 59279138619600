import Config from 'core/config';

const AuthUserKey = `${Config.client}_auth@icongressolive_user`;

const UserServices = {
    getUser: () => {
        if (localStorage.getItem(AuthUserKey)) {
            return JSON.parse(localStorage.getItem(AuthUserKey));
        }
        return {};
    },

    isLogged: () => {
        return localStorage.getItem(AuthUserKey);
    }
}

export default UserServices;