import Config from 'core/config';
const nameKey = 'itg@language';

const configLanguage = {
    languages: ['en', 'pt', 'es'],
    setLanguage: (lang) => {
        localStorage.setItem(nameKey, lang);
    },
    getLanguage: (lang) => {
        if (localStorage.getItem(nameKey)) {
            return localStorage.getItem(nameKey);
        }

        if (["wft2021", "riopipeline2021"].includes(Config.client)) {
            return "en";
        }

        return '';
    },
    getLanguageRouter: (inverse = false) => {
        if (localStorage.getItem(nameKey)) {
            if (!inverse)
                return '/' + localStorage.getItem(nameKey);
            if (inverse)
                return localStorage.getItem(nameKey) + '/';
        }
        return '';
    },
    removeLanguage: () => {
        localStorage.removeItem(nameKey);
    }
}

export default configLanguage;