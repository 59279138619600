import React, { useEffect, useState } from "react";
import { Container, Modal, Row, Col } from "react-bootstrap";
import { findAll } from "core/api";
import Config from "core/config";
import { tracking } from "components/Analytic";
import Sponsorship from "components/Sponsorship";
import SpeakerDetail from "components/Speaker";
import "./index.scss";
import Translate from "../../components/Translate";
import ConfigLanguage from "core/services/language";
import SettingServices from "services/SettingServices";

export default function Speaker({ showModal, setShowModal }) {
  const [data, setData] = useState(null);
  const [dataOrig, setDataOrig] = useState(null);
  const [item, setItem] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [height, setHeight] = useState(0);
  const settings = SettingServices.getItem();

  function handleShow(item) {
    if (showModal === true) {
      tracking({
        local_access_ctc_id: "8",
        speakerCostCenterId: item.palestrante_centro_custo_id,
      }); //palestrante
    }

    setItem(item);
    setShow(true);
  }
  useEffect(() => {
    setHeight(document.getElementsByClassName("modal-body")[0].offsetHeight);
  }, [document.getElementsByClassName("modal-body")]);

  useEffect(() => {
    if (showModal === true) {
      tracking({ local_access_ctc_id: "8" }); //palestrante
    }

    async function list() {
      const response = await findAll(
        `api/${ConfigLanguage.getLanguage(true)
          ? ConfigLanguage.getLanguage(true)
          : "pt-br"
        }/person/all/speaker`,
        { centro_custo_id: Config.centroCustoId }
      );
      setData(response.data);
      setDataOrig(response.data);
    }
    list();
  }, [showModal]);

  function renderImage(foto) {
    return <figure style={{ backgroundImage: `url(${foto})` }}></figure>;
  }

  function searchsPeaker(e) {
    const { value, name } = e.target;
    console.log(value);
    let data = Object.assign({}, dataOrig);
    data.participante = data.participante.filter(
      (s) =>
        s.palestrante === true &&
        s.nome.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
    setData(data);
  }

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName="modal-90w"
        size="xl"
        className="referencemodal speakermodal modal-sp"
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Container fluid>
              <Row>
                <div className="odd-padro main-speaker">
                  <div className="p-search">
                    <input
                      placeholder={
                        Translate({
                          transRef: "translation.Digite o nome para buscar",
                        }).props.children
                      }
                      onChange={searchsPeaker}
                    />
                  </div>
                  <Row>
                    {data &&
                      data.participante
                        .filter((item) => item.palestrante === true)
                        .map((item) => (
                          <Col sm={12} md={4} lg={3}>
                            <a
                              href
                              className="pointer main-speaker-link"
                              onClick={(e) => handleShow(item)}
                            >
                              {settings?.palestrante_exibir_foto === 'S' && renderImage(item.foto)}
                              <h2 className="main-speaker-name">{item.nome}</h2>
                            </a>
                          </Col>
                        ))}
                  </Row>
                </div>
                <div className="patroside">
                  <Sponsorship height={height} />
                </div>
              </Row>
              <SpeakerDetail
                show={show}
                handleClose={handleClose}
                item={item}
              />
            </Container>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
