import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { DivLoading } from './styles';
import Config from "core/config";

export default function Loading() {

    const dispatch = useDispatch();
    const { loading } = useSelector(state => state.application, shallowEqual);

    let timeOut = null;
    if (timeOut === null) {
        timeOut = setTimeout(() => {
            dispatch({ type: 'HIDE_LOADING' });
        }, 2000);
    }

    return (
        <DivLoading loading={loading} className={`${loading ? '' : 'endLoading'}`}>
            <img width="200px" src={`${Config.absolutePathImagem}/images/loading.gif`} alt="" />
        </DivLoading>
    );
}