import React from "react";

export default function Translate({ transRef, children }) {
  let translation = JSON.parse(localStorage.getItem("translation"));

  let arr = transRef.split(".");
  let object = translation;
  arr.map((ref) => {
    if (localStorage.getItem("translation")) {
      object = !["", null, undefined].includes(object[ref])
        ? object[ref]
        : arr[1];
    } else {
      object = !["", null, undefined].includes(object) ? object[ref] : arr[1];
    }
    return object;
  });

  return <>{object}</>;
}
