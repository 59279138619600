import Config from '../../core/config';
import SettingServices from '../../services/SettingServices';

const authToken = Config.nameAuthLoggedToken;
const settings = SettingServices.getItem();

const initialState = {
  settings,
  config: Config,
  data: {},
  isLogged: localStorage.getItem(authToken) !== null,
  hash: null,
  isLoggedToken: localStorage.getItem(`${Config.client}_auth@isLoggedToken`) || null,
};

const landpage = (state = initialState, action) => {
  switch (action.type) {
    case 'SET-MODAL':
      return { ...state, modal: action.modal };
    case 'GET-SETTINGS':
      return { ...state, settings: action.settings };
    case 'GET-CONFIG':
      return { ...state, config: action.config };
    case 'SET-DATA':
      return { ...state, data: action.data };
    case 'SET-HASH':
      return { ...state, hash: action.hash };
    case 'LOGIN':
      localStorage.setItem(authToken, action.isLoggedToken);
      return { ...state, isLogged: true, isLoggedToken: action.isLoggedToken };
    case 'LOGOUT':
      localStorage.removeItem(authToken);
      return { ...state, isLogged: false, isLoggedToken: action.isLoggedToken };
    default:
      return state;
  }
};

export default landpage;