const initialState = {    
    menu: true,
  };
  
export default (state = initialState, action) => {
    switch (action.type) {
        case "SET_MENU":
            return { ...state, menu: action.menu };
        default:
            return state;
    }
};