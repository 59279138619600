import Swal from 'sweetalert2';
import React from 'react';
import Translate from '../../../components/Translate';

const Message = {
    success: (msg = '') => {
        Swal.fire({
            title: Translate({transRef:"translation.Sucesso"}).props.children,
            text: msg || Translate({transRef:"translation.Login efetuado com sucesso"}).props.children,
            icon: 'success',
        });
    },
    error: (msg) => {
        Swal.fire({
            title: Translate({transRef:"translation.Oops"}).props.children,
            text: msg || Translate({transRef:"translation.Erro ao efetuar este procedimento, por favor, tente novamente"}).props.children,
            icon: 'error',
            confirmButtonText: 'OK'
        });
    },
    infor: (msg) => {
        Swal.fire({
            title: Translate({transRef:"translation.Atencao!"}).props.children,
            text: msg || Translate({transRef:"translation.Erro ao efetuar este procedimento, por favor, tente novamente"}).props.children,
            icon: 'info',
            confirmButtonText: 'OK'
        });
    },
    confirmation: async (msg =  Translate({transRef:"translation.Deseja excluir este registro?"}).props.children
    , title = 'Exclusão'
    , allowOutsideClick = true) => {
        let res = false;
        await Swal.fire({
          title: title,
          text: msg,
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok',
          allowOutsideClick: allowOutsideClick
        }).then(result => {
          if (result.value) {
            res = true;
          }
        });
        return res;
    },
    confirm: async (msg = Translate({transRef:"translation.Deseja excluir este registro?"}).props.children, title = 'Exclusão') => {
        let res = false;
        await Swal.fire({
          title: title,
          text: msg,
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: Translate({transRef:"translation.SIM"}).props.children,

          showCancelButton:true,
          cancelButton:'red',
          cancelButtonText: Translate({transRef:"translation.NAO"}).props.children
        }).then(result => {
          if (result.value) {
            res = true;
          }
        });
        return res;
    }
    
};

export default Message;