import React, { useEffect } from 'react';
import AuthenticateServices from 'services/AuthenticateServices';
import Config from 'core/config';
import io from 'socket.io-client';

export default function Login() {
    useEffect(() => {
        const AuthUserKey = `${Config.client}_auth@icongressolive_user`;
        const user = AuthenticateServices.getUser();

        if (user !== undefined && user?.id) {
            const options = {
                secure: true,
                reconnect: true,
                rejectUnauthorized: false,
                forceNew: true, // Força uma nova conexão
                reconnectionAttempts: 5, // Limita o número de tentativas de reconexão
                reconnectionDelay: 2000, // Atraso entre as tentativas de reconexão
                query: {
                    user: JSON.stringify(user),
                    client: Config.client,
                }
            };

            const socket = io(Config.urlSocket, options);
            window.socket = socket;

            const clearSessionHandler = async (data) => {
                try {
                    const result = JSON.parse(data);
                    console.log('CLEAR: ', result);

                    if (parseInt(user.id) === parseInt(result.id)) {
                        if (window.ZoomMtg !== undefined)
                            window.ZoomMtg.leaveMeeting({});

                        AuthenticateServices.generateTokenAuthenticate();
                        localStorage.removeItem(Config.nameAuthLoggedToken);
                        localStorage.removeItem(AuthUserKey);
                        window.location = Config.client === 'fbgcursos' ? '/mobile' : '/';
                    }
                } catch (error) {
                    console.error('Error clearing session:', error);
                    AuthenticateServices.generateTokenAuthenticate();
                    localStorage.removeItem(Config.nameAuthLoggedToken);
                    localStorage.removeItem(AuthUserKey);
                    window.location = '/';
                }
            };

            const tokenUpdateHandler = async (data) => {
                try {
                    const result = JSON.parse(data);
                    if (user.id === result.id) {
                        AuthenticateServices.generateTokenAuthenticate();
                    }
                } catch (error) {
                    console.error('Error updating token:', error);
                }
            };

            socket.on('clear_session', clearSessionHandler);
            socket.on('token_update', tokenUpdateHandler);

            const interval = setInterval(() => {
                socket.emit('update_redis', JSON.stringify(user));
            }, 40 * 1000);

            return () => {
                clearInterval(interval);
                socket.off('clear_session', clearSessionHandler);
                socket.off('token_update', tokenUpdateHandler);
                socket.disconnect();
            };
        }
    }, []);

    return null;
}