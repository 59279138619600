import {Component} from 'react';
import packageJson from '../package.json';

global.appVersion = packageJson.version;
const semverGreaterThan = (versionA, versionB) => {
    const versionsA = versionA.split(/\./g);
    const versionsB = versionB.split(/\./g);
    while (versionsA.length || versionsB.length) {
        const a = Number(versionsA.shift());
        const b = Number(versionsB.shift());
        if (a === b) continue;
        return a > b || isNaN(b);
    }
    return false;
};

class CacheBuster extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            isLatestVersion: false,
            refreshCacheAndReload: () => {
                if ('caches' in window) {
                    caches.keys().then(function (names) {
                        for (let name of names) {
                            caches.delete(name);
                        }
                    });
                    window.location.reload();
                }
            }
        };
    }

    componentDidMount() {
        fetch('/meta.json')
            .then((response) => response.json())
            .then((meta) => {
                const latestVersion = meta.version;
                const currentVersion = global.appVersion;
                const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
                if (shouldForceRefresh) {
                    this.setState({loading: false, isLatestVersion: false});
                } else {
                    this.setState({loading: false, isLatestVersion: true});
                }
            });
    }

    render() {
        const {loading, isLatestVersion, refreshCacheAndReload} = this.state;
        return this.props.children({loading, isLatestVersion, refreshCacheAndReload});
    }
}

export default CacheBuster;