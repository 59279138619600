const initialState = {    
    href: '',
    iframe: false,
  };
  
export default (state = initialState, action) => {
    switch (action.type) {
        case "SET_HREF":
            return { ...state, href: action.href };
        case "SET_IFRAME":
            return { ...state, iframe: action.iframe };
        default:
            return state;
    }
};