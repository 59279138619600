import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import configureStore from "./redux/stores";
import AppRouter from "./routes/AppRouter";
import "./App.scss";
import "./Responsive.scss";
import Analytic from "./components/Analytic";
import Login from "./components/Login";
import translation from "_i18n/";
import AuthenticateServices from "services/AuthenticateServices";
import { create, update } from "core/api";
import Echo from "laravel-echo";
import Pusher from "pusher-js";
import ClearCache from "ClearCache";
import configLanguage from "core/services/language";
import Config from "core/config";


function App() {
  const [state, setState] = useState(false);
  const [online, setOnline] = useState(0);
  const user = AuthenticateServices.getUser();

  useEffect(() => {
  }, [online]);

  useEffect(() => {
    async function list() {
      await AuthenticateServices.generateTokenAuthenticate();
      await translation();
      setState(true);
    }
    list();
    if (user.id) {
      // AuthenticateServices.refreshLogged();

      setInterval(() => {
        AuthenticateServices.refreshLogged();
      }, 1200000);
    }

  }, [user.id]);

  useEffect(() => {
    if (user.id) {
      const options = {
        broadcaster: "pusher",
        wsHost: Config.urlWs,
        wssHost: Config.urlWs,
        authEndpoint: Config.baseUrl + "api/broadcasting/auth",
        wsPort: Config.wsPort,
        wssPort: Config.wsPort,
        key: Config.wsKey,
        disableStats: true,
        enabledTransports: ["ws", "wss"],
        encrypted: false,
        cluster: "mt1",
        auth: {
          headers: {
            Authorization: "Bearer " + user.access_token,
          },
        },
        forceTLS: false,
      };

      const echo = new Echo(options);
      echo
        .join("online." + Config.centroCustoId)
        .here(function (members) {
          localStorage.setItem('online', 1);
          setOnline(localStorage.getItem('online'));
          //quando entrar temos que marcar a entrada.
          create(
            `api/pt-br/icongressolive/online`, {
            pessoa_id: members?.[0]?.id
          })

        }).joining(function (member) {
          localStorage.setItem('online', (parseInt(localStorage.getItem('online')) + 1));
          setOnline(localStorage.getItem('online'));
        })
        .leaving(function (leavingMember) {
          localStorage.setItem('online', (parseInt(localStorage.getItem('online')) - 1));
          setOnline(localStorage.getItem('online'));
          //quando sai tem que marcar a saida
          update(
            `api/pt-br/icongressolive/online/person/leaving`, {
            pessoa_id: leavingMember?.id
          })
        });
    }
  }, [user.access_token, user.id]);

  return (
    state && (
      <div className="App">
        <ClearCache>
          {
            // @ts-ignore
            ({ loading, isLatestVersion, refreshCacheAndReload }) => {
              if (loading) return null;
              if (!loading && !isLatestVersion) {
                refreshCacheAndReload();
              }
              return (<Provider store={configureStore()}>
                <AppRouter />
                <Analytic />
                <Login />
              </Provider>);
            }}
        </ClearCache>
      </div>
    )
  );
}

export default App;
