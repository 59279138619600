// Config Client CentroCustoId
const ClientServices = {
    init: async (dispatch) => {
        
        const clientUrl = window.location.href.split('.');
        const client = clientUrl[0];

        const url = window.location.pathname.split('/');
        if(url[1] === "") {
            url[1] = 3;
        }
        dispatch({type: 'SET_CENTRO_CUSTO_ID', centroCustoId: url[1], client});
    },
}

export default ClientServices;