import React from 'react';

const Textarea = ({
  name,
  label,
  field,
  required,
  erros,
  ...props
}) => {
  return (
    <div className="form-group" style={{ width: '100%' }}>
        {name && <label htmlFor={name}>
            {label} {required ? <span className="required"> *</span> : '' }
        </label>}
      <textarea
        name={name}
        className="form-control"
        {...field}
        {...props}
      ></textarea>
      <span style={{ color: 'red' }}>{erros}</span>
    </div>
  );
};

export default Textarea;
