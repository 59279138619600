import Config from 'core/config';

const initialState = {
    data: {},
    isLogged: false,
    isLoggedToken: '',
};

const authToken = Config.nameAuthLoggedToken;
  
export default (state = initialState, action) => {
    switch (action.type) {
        case "LOGIN":
            localStorage.setItem(authToken, action.isLoggedToken);
            return { ...state, isLogged: true, isLoggedToken: action.isLoggedToken };

        case "LOGOUT":
            localStorage.removeItem(authToken);
            return { ...state, isLogged: false, isLoggedToken: action.isLoggedToken };

        default:
            if(localStorage.getItem(authToken))
                return { ...state, isLogged: true, isLoggedToken: localStorage.getItem(authToken) };
        return state;
    }
};