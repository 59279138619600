import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import AuthenticateServices from "../../services/AuthenticateServices";
import { useSelector } from "react-redux";
import configLanguage from "core/services/language";
import Translate from "components/Translate";
import { Navbar, Nav, Modal, Button, NavDropdown } from "react-bootstrap";
import SettingServices from "services/SettingServices";
import "./index.scss";
import { MobileView, BrowserView } from "react-device-detect";
import Config from "core/config";
import { findAll, update } from "core/api";
import convertBase64 from "../../core/util/convertBase64";
import { AiFillEdit } from "react-icons/ai";

export default function Menu() {
  const dispatch = useDispatch();
  const { isLogged } = useSelector((state) => state.auth);
  const user = AuthenticateServices.getUser();
  const [menu, setMenu] = useState([]);
  const [useGamification, setUseGamification] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [gamificationData, setGamificationData] = useState([]);

  function setModal(modal) {
    dispatch({ type: "SET_MODAL", modal });
  }

  async function logout() {
    AuthenticateServices.logout();
  }

  function getLink(i) {
    console.log(i);return;
    if (i.href !== "") {
      i.target = i.target !== undefined ? i.target : "";
      return { href: i.href, target: i.target };
    } else {
      return {
        onClick: () => {
          setModal(i.onclick);
        },
      };
    }
  }

  useEffect(() => {
    async function list() {
      await SettingServices.get();
      const setting = SettingServices.getItem();
      if (setting && setting.json_menu !== null) {
        setting.json_menu = JSON.parse(setting.json_menu);

        if (setting?.exibir_faq_menu !== undefined
          && setting?.exibir_faq_menu === 'N') {
          setting.json_menu = setting.json_menu.filter((a) => a.onclick !== "faq");
        }

        setMenu(setting.json_menu);
      }

      if (setting?.dashboard_gamificatiom !== undefined &&
        setting?.dashboard_gamificatiom === 'S') {
        setUseGamification(true);
      }
    }
    list();
  }, []);

  async function getGamification() {
    const response = await findAll(
      `api/${configLanguage.getLanguage(true)
        ? configLanguage.getLanguage(true)
        : "pt-br"
      }/person/${user?.id}/gamification?centro_custo_id=${Config.centroCustoId}`
    );
    setGamificationData(response.data);
  }

  // useEffect(() => {
  //   if (user?.id) {
  //     getGamification();
  //   }
  // }, []);

  function changeLanguage(lang) {
    configLanguage.setLanguage(lang);
    window.location = "/";
  }

  function openGamificationModal() {
    if (user?.id) {
      getGamification();
    }
    setShowModal(true);
  }

  function closeGamificationModal() {
    setShowModal(false);
  }

  async function sendPhoto(e) {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);

    try {
      const response = await update(
        `api/${configLanguage.getLanguage(true)
          ? configLanguage.getLanguage(true)
          : "pt-br"
        }/person/${user?.id}`,
        {
          id: user.id,
          foto: base64,
        }
      );

      // AuthenticateServices.authenticate();
      document.location.reload();
    } catch (error) {
      console.log("erro", error);
    }
  }
  if (['rog22'].includes(Config.client)) {
    return (<></>)
  }
  return (
    <div>
      <MobileView>
        {isLogged ? (
          <div className="user-mobile">
            <div className="image_outer_container">
              <div className="green_icon"></div>
              <div className="image_inner_container avatar-login">
                <div></div>
                <img
                  title={user.name}
                  src={user.photo || `${Config.absolutePathImagem}/images/avatar-profile.png`}
                  alt=""
                />
                <Nav.Link onClick={(e) => logout()}>
                  <Translate transRef="translation.sair" />
                </Nav.Link>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </MobileView>

      <Modal show={showModal} onHide={closeGamificationModal}>
        <Modal.Header closeButton>
          <Modal.Title>Pontos de Gamificação</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modalBody">
          <div className="uploadContainer">
            <label htmlFor="btn-upload">
              <input
                id="btn-upload"
                name="btn-upload"
                style={{ display: "none" }}
                type="file"
                accept="image/*"
                onChange={(e) => sendPhoto(e)}
              />

              <div class="overlay">
                <AiFillEdit />
              </div>
            </label>
            <img
              title={user.name}
              src={user.photo || `${Config.absolutePathImagem}/images/avatar-profile.png`}
              alt=""
              style={{ cursor: "pointer" }}
              className="img-person"
            />
          </div>

          <div>
            {gamificationData.map((row, i) => (
              <div key={i} className="row-gamification">
                <p className="text-description">{row.descricao}</p>
                <h5>&nbsp;{row.pontuacao}</h5>
              </div>
            ))}
            <div className="row-gamification">
              <p className="text-description">Total</p>
              <h5>
                {gamificationData
                  .map((r) => r.pontuacao)
                  .reduce((total, numero) => {
                    return parseInt(total) + parseInt(numero);
                  }, 0)}
              </h5>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeGamificationModal}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      <Navbar collapseOnSelect expand="lg" className="navmenu">
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="centering-menu">
            {menu.length > 0 ? (
              menu
                .filter((m) => m !== null && m.visible === true)
                .map((m, i) => (
                  m.isDropDown ?
                    <NavDropdown title={<Translate transRef={m.translation} />} className="landingPage-link-menu" id="nav-dropdown">
                      {m.dropDownMenu.length > 0 && m.dropDownMenu.map((mm, ii) => {
                        return (
                          <NavDropdown.Item {...getLink(mm)} eventKey={ii} className="landingPage-link-sub-menu">
                            <Translate transRef={mm.translation} />
                          </NavDropdown.Item>
                        );
                      })}
                    </NavDropdown>
                    :
                    <Nav.Link {...getLink(m)} key={i}>
                      <Translate transRef={m.translation} />
                    </Nav.Link>
                ))
            ) : (
              <>
                <Nav.Link href={"/"}>
                  <Translate transRef="translation.entrada" />
                </Nav.Link>

                <Nav.Link onClick={(e) => setModal("orientation")}>
                  <Translate transRef="translation.informacoes" />
                </Nav.Link>

                <Nav.Link onClick={(e) => setModal("scientific-programming")}>
                  <Translate transRef="translation.programacao cienticica" />
                </Nav.Link>

                {!["abes-dn", "localhost"].includes(Config.client) && (
                  <Nav.Link href={"/exhibitor"}>
                    <Translate transRef="translation.expositores" />
                  </Nav.Link>
                )}

                <Nav.Link
                  onClick={() => changeLanguage("pt-br")}
                  style={{ padding: "0 2px" }}
                >
                  <img
                    title={user.name}
                    src={`${Config.absolutePathImagem}/images/pt.jpg`}
                    alt="pt"
                    style={{ width: 20 }}
                  />
                </Nav.Link>

                <Nav.Link
                  onClick={() => changeLanguage("en")}
                  style={{ padding: "0 2px" }}
                >
                  <img
                    title={user.name}
                    src={"/images/rn.jpg"}
                    alt="en"
                    style={{ width: 20 }}
                  />
                </Nav.Link>
              </>
            )}
            <BrowserView>
              {isLogged ? (
                <Nav.Link onClick={(e) => logout()}>
                  <Translate transRef="translation.sair" />
                </Nav.Link>
              ) : (
                ""
              )}
            </BrowserView>

            <BrowserView>
              {isLogged ? (
                <div className="d-flex justify-content-center h-100 margin-top-15">
                  <div className="image_outer_container">
                    <div className="green_icon"></div>
                    <div className="image_inner_container avatar-login">
                      <img
                        title={user.name}
                        src={user.photo || `${Config.absolutePathImagem}/images/avatar-profile.png`}
                        alt=""
                        style={{ cursor: "pointer" }}
                        onClick={() => useGamification === true ? openGamificationModal() : ""}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </BrowserView>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}
