const initialState = {    
    modal: '',
    id: '',
  };
  
export default (state = initialState, action) => {
    switch (action.type) {
        case "SET_MODAL":
            return { ...state, modal: action.modal };
        case "SET_ID":
            return { ...state, id: action.id };
        default:
            return state;
    }
};