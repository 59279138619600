import React, { createRef, useEffect } from 'react';
import { Modal, Container, Row, Image } from 'react-bootstrap';
import Sponsorship from 'components/Sponsorship';
import { tracking } from 'components/Analytic';
import Config from 'core/config';
import './index.scss';
export default function SponsorsOrganization({ showModal, setShowModal }) {

  const modal = createRef();
  useEffect(() => {
    if (showModal === true)
      tracking({ local_access_ctc_id: "34" });//PATROCINADORES E  ORGANIZAÇÃO -- ok
  });

  var urlImage = 'organizacao.jpg';
  if (window.innerWidth <= 600) {
    var urlImage = 'organizacao-mobile.png';
  }
  return (
    <>
      <Modal
        ref={modal}
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName="modal-90w modal-or"
        size='xl'
        className="referencemodal orientationmodal"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{
            backgroundColor: "white"
          }}>
            ORGANIZAÇÃO
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <Row>
              <div className="odd-padro">
                <Image src={`${Config.absolutePathImagem}/images/scene/client/${Config.client}/organization/${urlImage}`} className="odd-patro-image" />
              </div>
              <div className="patroside">
                <Sponsorship />
              </div>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
}