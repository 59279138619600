import styled from 'styled-components';

export const DivLoading = styled.div`

    position: fixed;
    overflow: hidden !important;
    z-index: 99999999;
    width: 100%;
    height: 100vh;
    background: rgba(255,255,255) ;
    color: #FFF;
    animation: effectLoading 1s;
    display: ${props => props.loading ? 'block;' : 'none'};
     
    img {
        margin: 0;
        background: rgba(255,255,255,0.8);
        position: absolute;
        top: 40%;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%)
    }
`;
