import Config from 'core/config';
import SettingServices from 'services/SettingServices';
let keys = [];
const setting = SettingServices.getItem();

if (Config.client === 'localhost' || Config.client === 'conline') {
    keys = [
        // POR FAVOR, NÃO MEXER EM NENHUM DESSAS CHAVES
        {
            id: 0,
            company: 'Bem vindo',
            key: 'f1ee9970-91e6-498d-b11c-79560d3c2f36',
            stand: '1'
        },
        {
            id: 6,
            company: 'ITARGET',
            key: '7d402fcd-c77b-4c09-8aa0-733a56bd955f',
            stand: '1'
        },
        {
            id: 7,
            company: 'ABO',
            key: 'a878ceac-bd45-4a94-9dae-6bf0013806ec',
            stand: '1'
        }
        /*{
            id: 3,
            company: 'OZONTOP',
            key: '7d402fcd-c77b-4c09-8aa0-733a56bd955f',
            stand: '2'
        },
        {
            id: 4,
            company: 'ANGEVAN',
            key: 'a878ceac-bd45-4a94-9dae-6bf0013806ec',
            stand: '3'
        },
        {
            id: 5,
            company: 'OZONE&LIFE',
            key: '131a8629-faf2-49d5-b08a-74f53b66b858',
            stand: '4'
        },
        {
            id: 6,
            company: 'PHILOZON',
            key: '696f1c8a-e8d2-4d2d-88c5-741d15b2b31d',
            stand: '5'
        },*/
    ];


} else {
    keys = [
        // POR FAVOR, NÃO MEXER EM NENHUM DESSAS CHAVES
        // {
        //     id: 0,
        //     company: 'Itarget Tecnologia',
        //     key: 'f1ee9970-91e6-498d-b11c-79560d3c2f36',
        //     stand: '1'
        // },
        // {
        //     id: 37,
        //     company: 'SBACV NACIONAL',
        //     key: '7d402fcd-c77b-4c09-8aa0-733a56bd955f',
        //     stand: '2'
        // },
        // {
        //     id: 34,
        //     company: 'CONVATEC BRASIL',
        //     key: 'a878ceac-bd45-4a94-9dae-6bf0013806ec',
        //     stand: '3'
        // },
        // {
        //     id: 30,
        //     company: 'MINDRAY DO BRASIL COM. E DISTR. DE EQUIP. MÉDICOS LTDA',
        //     key: '131a8629-faf2-49d5-b08a-74f53b66b858',
        //     stand: '4'
        // },
        // {
        //     id: 4,
        //     company: 'LABORATÓRIOS SERVIER DO BRASIL LTDA',
        //     key: '696f1c8a-e8d2-4d2d-88c5-741d15b2b31d',
        //     stand: '5'
        // },
        // {
        //     id: 24,
        //     company: 'APSEN FARMACÊUTICA S/A',
        //     key: '1ea1c1a8-bf5f-4d36-ad20-08842e829dff',
        //     stand: '6'
        // },
        // {
        //     id: 23,
        //     company: 'EMILCARDIO PRODUTOS HOSPITALARES EIRELI',
        //     // key: 'cf9ba39e-a495-43f2-b29f-4edf0a86f121', 
        //     key: '81b5a0be-a5a6-4602-819b-86b86549b886', // Novo
        //     stand: '7'
        // },
        // {
        //     id: 36,
        //     company: 'BAYER S.A',
        //     key: 'af2c57a0-677a-4674-82d2-af2a4729db31',
        //     stand: '8'
        // },
        // {
        //     id: 20,
        //     company: 'MULTIVISION COMÉRCIO DE MATERIAL HOSPITALAR EIRELI',
        //     key: '64690c9f-43ad-40c0-8f58-9894200444e9',
        //     stand: '9'
        // },
        // {
        //     id: 21,
        //     company: 'MEDI BRASIL COMÉRCIO E DISTRIBUIÇÃO DE MEIAS ELÁSTICAS LTDA',
        //     key: '18d0a5f4-cce5-440a-bf88-9d465094a36d',
        //     stand: '10'
        // },
        // {
        //     id: 35,
        //     company: 'MÖLNLYCKE HEALTH CARE VENDA DE PRODUTOS MÉDICOS LTDA',
        //     key: 'dcb4f306-26de-4efb-9bc7-24663bc038d8',
        //     stand: '11'
        // },
        // {
        //     id: 26,
        //     company: 'SOCIEDADE BRASILEIRA DE DIREITO MÉDICO E BIO-ÉTICA',
        //     key: '42a2bf24-a83a-4ee7-9558-6a72750c4af1',
        //     stand: '12'
        // },
        // {
        //     id: 22,
        //     company: 'BARD BRASIL IND. E COM. DE PRODUTOS PARA SAUDE LTDA',
        //     key: '538912da-f1c8-4779-9fc4-2547c42e3bdc',
        //     stand: '13'
        // },
        // {
        //     id: 29,
        //     company: 'SBACV – REGIONAL ESPÍRITO SANTO',
        //     // key: 'aacbcb9b-58e1-4cae-9bdc-5d678e0361c8', 
        //     key: 'ac2aaaf3-c0fc-4a76-a233-bf37306a0459', // Novo
        //     stand: '14'
        // },
        // {
        //     id: 38,
        //     company: 'ENDOVIX IMP. E COM. DE MAT. HOSPITALAR EIRELI',
        //     // key: '96e095a2-865d-40eb-b533-3eee7751fbcb', 
        //     key: '63bca597-bc9b-45e5-bd8c-e8e66c4fe18d', // Novo
        //     stand: '15'
        // },
        // {
        //     id: 27,
        //     company: 'OXY CAMARAS',
        //     // key: '97ba179d-e7ad-41df-a666-572bdb0c8eb9', 
        //     key: '85e826ac-94ac-46a4-b50b-c86b8151fa1a', // Novo
        //     stand: '16'
        // },
    ];
}

if (Config.client === 'cbc') {
    keys = [
        // POR FAVOR, NÃO MEXER EM NENHUM DESSAS CHAVES
        {
            id: 0,
            company: 'Bem vindo',
            key: 'f1ee9970-91e6-498d-b11c-79560d3c2f36',
            stand: '1'
        }
    ]
}
if (Config.client === 'mezcla') {
    keys = [
        {
            id: 0,
            company: 'Bem vindo',
            key: '7d402fcd-c77b-4c09-8aa0-733a56bd955f',
            stand: '1'
        }
    ]
}

if (setting && setting.chat_chave !== null && setting.chat_chave !== undefined) {
    keys = JSON.parse(setting.chat_chave);
}

const ListKeys = keys;
export default ListKeys;