import React from "react";
import * as Yup from "yup";
import { Modal, Row, Col, Image, Button } from "react-bootstrap";
import { Formik, Field, ErrorMessage } from "formik";
import Input from "components/Form/Input";
import Textarea from "components/Form/Textarea";
import { create } from "core/api";
import Config from "core/config";
import Message from "core/util/Message";
import AuthenticateServices from "services/AuthenticateServices";
import { setToken } from "core/services/auth";
import UserServices from "services/UserServices";
import SettingServices from "services/SettingServices";
import Translate from "../Translate";
import ConfigLanguage from "core/services/language";

export default function SpeakerDetail({ show, handleClose, item }) {
  const user = UserServices.getUser();
  const setting = SettingServices.getItem();
  const validationSchema = Yup.object().shape({
    nome_remetente: Yup.string().required(
      <Translate transRef="translation.Campo Obrigatorio" />
    ),
    email_remetente: Yup.string().required(
      <Translate transRef="translation.Campo Obrigatorio" />
    ),
  });

  async function sendMail(values, { setSubmitting }) {
    // Authenticate Token
    const responseToken = await AuthenticateServices.authenticateSite();
    await setToken(responseToken);

    if (!values.email) {
      Message.error(
        Translate({
          transRef: "translation.Palestrante sem email de contato configurado!",
        }).props.children
      );
      return false;
    }

    const response = await create(
      `api/${ConfigLanguage.getLanguage(true)
        ? ConfigLanguage.getLanguage(true)
        : "pt-br"
      }/send-mail`,
      values
    );
    Message.success(response.message);
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} className="modal-unit-speaker">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row>
            {setting?.palestrante_exibir_foto === "S" && (
              <Col xs={12} sm={12} md={4}>
                <Image
                  src={item ? item.foto : "https://via.placeholder.com/500"}
                  fluid
                  thumbnail
                  className="modal-unit-speaker-avatar"
                />
              </Col>
            )}
            <Col xs={12} sm={12} md={setting?.palestrante_exibir_foto === "S" ? 8 : 12}>
              <h2 className="modal-unit-speaker-name">
                {item ? item.nome : ""}
              </h2>
              <h4 className="modal-unit-speaker-title">
                <Translate transRef="translation.Titulacao do Palestrante" />
              </h4>
              <p className="modal-unit-speaker-curriculum">
                {item &&
                  ["pt-br", "", undefined, null].includes(
                    localStorage.getItem("language")
                  )
                  ? item.biografia
                  : item &&
                  (![null, undefined, ""].includes(item.biografia_ingles)
                    ? item.biografia_ingles
                    : "")}
              </p>
              {UserServices.isLogged() &&
                setting &&
                setting.palestrante_comunicacao === "S" && (
                  <Formik
                    initialValues={{
                      assunto: "",
                      mensagem: "",
                      nome: item ? item.nome : "",
                      email: item ? item.email : "",
                      nome_remetente: UserServices.isLogged() ? user.name : "",
                      email_remetente: UserServices.isLogged()
                        ? user.email
                        : "",
                      centro_custo_id: Config.centroCustoId,
                      tipo_email_id: "64",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={sendMail}
                  >
                    {({ handleChange, handleSubmit, isSubmitting }) => (
                      <form onSubmit={handleSubmit}>
                        <Row>
                          <b>
                            <Translate transRef="translation.Envie mensagem ao palestrante" />
                          </b>
                        </Row>
                        <hr />
                        <Row>
                          <Field
                            placeholder={
                              Translate({ transRef: "translation.Assunto" })
                                .props.children
                            }
                            type="text"
                            name="assunto"
                            onChange={handleChange}
                            component={Input}
                            erros={<ErrorMessage name="assunto" />}
                          />
                        </Row>
                        <Row>
                          <Field
                            placeholder={
                              Translate({ transRef: "translation.Mensagem" })
                                .props.children
                            }
                            type="text"
                            name="mensagem"
                            onChange={handleChange}
                            component={Textarea}
                            erros={<ErrorMessage name="mensagem" />}
                          />
                        </Row>
                        <Row style={{ float: "right" }}>
                          <Button
                            type="submit"
                            variant="outline-secondary"
                            size="sm"
                            disabled={isSubmitting}
                          >
                            <Translate transRef="translation.Enviar" />
                          </Button>
                        </Row>
                      </form>
                    )}
                  </Formik>
                )}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
