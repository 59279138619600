const initialState = {
    loading: true,
};
  
export default (state = initialState, action) => {
    switch (action.type) {
        case "LOADING_DATA":
            return { ...state, };

        case "SHOW_LOADING":
            return { ...state, loading: true };

        case "HIDE_LOADING":
            return { ...state, loading: false };

        default:
        return state;
    }
};