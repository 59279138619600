const initialState = {
  sala: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_SALA":
      return { ...state, sala: action.sala };
    default:
      return state;
  }
};
