import Config from 'core/config';
const keyToken = `${Config.client}_auth@icongressolive`;


export const isLogged = () => {    
    if(localStorage.getItem(keyToken)) {
        return true;
    }
    return false;
};

export const getToken = () => {    
    return `${window.localStorage.getItem(keyToken)}`;
};

export const setToken = (token) => {
    window.localStorage.setItem(keyToken, token);
};