import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Switch, Route } from 'react-router';
import MainRouter from '../MainRouter';
import { useDispatch } from 'react-redux';
import ClientServices from 'services/ClientServices';

export default function AppRouter() {
    
    const dispatch = useDispatch();
    ClientServices.init(dispatch);

    return (
        <>
            <BrowserRouter>
                <Switch>
                    <Route path='/' component={MainRouter} />
                </Switch>
            </BrowserRouter>
        </>
    );
}