import React, { useEffect, useState } from 'react';
import { Row, Col, Carousel } from 'react-bootstrap';
import Config from 'core/config';
import Translate from '../Translate';
import ConfigLanguage from 'core/services/language';

export default function Sponsorship(props) {
    const [patrocinios, setPatrocinios] = useState([]);

    useEffect(() => {
        let patrocinios = [
            { id: 1, url: `${Config.absolutePathImagem}/images/scene/client/${Config.client}/patro/${props && props.orientation ? props.orientation : "001.jpg" }` }
        ];
        setPatrocinios(patrocinios);
    }, []);

    return (
        <>
            <center>
                <Row>
                    <Col>
                        <Carousel fade="true" controls='' indicators='' pause="false">
                            {patrocinios.map(item => <Carousel.Item>
                                <img src={item.url} alt="" style={{ height: props.height - 50 }} />
                            </Carousel.Item>)}
                        </Carousel>
                    </Col>
                </Row>
            </center>
        </>
    );
}