import { create, remove, update } from "core/api";
import { setToken } from "core/services/auth";
import Message from "core/util/Message";
import Config from "core/config";
import ConfigLanguage from "core/services/language";
import Translate from "../../components/Translate";
const AuthUserKey = `${Config.client}_auth@icongressolive_user`;

const AuthenticateServices = {
  authenticateSite: async () => {
    const response = await create(
      `api/${ConfigLanguage.getLanguage(true)
        ? ConfigLanguage.getLanguage(true)
        : "pt-br"
      }/authenticate`,
      {
        login: "icongressolive",
        senha: "FRB:y}|Y",
      }
    );
    return response.token;
  },
  generateTokenAuthenticate: async () => {
    const token = await AuthenticateServices.authenticateSite();
    await setToken(token);
  },
  isLogged: () => { },

  refreshLogged: async () => {
    const user = AuthenticateServices.getUser();
    if (user) {
      AuthenticateServices.generateTokenAuthenticate();
      const response = await update(
        `api/${ConfigLanguage.getLanguage(true)
          ? ConfigLanguage.getLanguage(true)
          : "pt-br"
        }/login/${user.id}`,
        {
          uuid: user.id,
        },
        user.access_token
      );
      localStorage.setItem(
        AuthUserKey,
        JSON.stringify({ ...user, access_token: response.access_token, token: response.token })
      );
    }
  },
  logout: async () => {
    const user = AuthenticateServices.getUser();
    
    localStorage.removeItem(Config.nameAuthLoggedToken);
    localStorage.removeItem(AuthUserKey);
    localStorage.removeItem("toLogin")

    if (user && user.id !== undefined) {
      AuthenticateServices.generateTokenAuthenticate();
      await remove(
        `api/${ConfigLanguage.getLanguage(true)
          ? ConfigLanguage.getLanguage(true)
          : "pt-br"
        }/login/${user.id}`,
        user.id,
        user.access_token
      );

      window.socket.emit("logout", JSON.stringify(user));
    }

    let textSession = Translate({ transRef: "translation.Sessão" }).props
      .children;
    const check = await Message.confirmation(Config.messageExit, textSession, false);

    if (check) {
      window.location = "/";
    }
  },
  setUser: (params) => {
    const date = new Date();
    date.setHours(date.getHours() + Config.setTimeExpiredHour);
    if (params.data.foto) {
      params.data.foto = params.data.foto.replace("icase", "icongresso");
    }
    const user = {
      id: params.data.uuid,
      email: params.data.email,
      name: params.data.nome,
      tokenExpired: date.getTime(),
      photo: params.data.foto,
      ip: params.data.ip,
      access_token: params.access_token,
    };
    localStorage.setItem(AuthUserKey, JSON.stringify(user));
  },
  getUser: () => {
    if (localStorage.getItem(AuthUserKey)) {
      return JSON.parse(localStorage.getItem(AuthUserKey));
    }
    return {};
  },

  checkTimeExpired: async () => {
    if (localStorage.getItem(AuthUserKey)) {
      const user = JSON.parse(localStorage.getItem(AuthUserKey));
      const timeExpiredToken = new Date(user.tokenExpired);
      const dateNow = new Date();
      const checkExpired = timeExpiredToken <= dateNow;
      if (checkExpired) {
        AuthenticateServices.logout();
      }
    }
  },
};

export default AuthenticateServices;
