import axios from "axios";
import Config from "core/config";
import { getToken } from "core/services/auth";
import Message from "core/util/Message";
import ConfigLanguage from "core/services/language";

export function Api() {
  const api = axios.create({
    headers: { Authorization: "Bearer " + getToken() },
    baseURL: Config.baseUrl,
  });

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (e) => {
      Message.error(e.response.data.message);
      return Promise.reject(e);
    }
  );
  return api;
}

export async function findById(endPoint, query) {
  query = { ...query, per_page: 99999 };
  const response = await Api().get(`${endPoint}`, { params: query });
  return response.data;
}

export async function findAll(endPoint, data) {
  // data = { ...data, per_page: 99999 };
  const response = await Api().get(`${endPoint}`, { params: data });
  return response.data;
}

export async function create(endPoint, data, token) {
  const response = await Api().post(`${endPoint}`, data, {
    headers: { Authorization: "Bearer " + (token ? token : getToken()) },
  });
  return response.data;
}

export async function remove(endPoint, id, token) {
  const response = await Api().delete(`${endPoint}`, {
    headers: { Authorization: "Bearer " + (token ? token : getToken()) },
  });
  return response.data;
}

export async function update(endPoint, data, token) {
  const response = await Api().put(`${endPoint}`, data, {
    headers: { Authorization: "Bearer " + (token ? token : getToken()) },
  });
  return response.data;
}
