import { findAll } from "core/api";
import Config from "core/config";
import ConfigLanguage from "core/services/language";

export default async function getTranslation() {
  const respose = await findAll(
    `api/${
      ConfigLanguage.getLanguage(true)
        ? ConfigLanguage.getLanguage(true)
        : "pt-br"
    }/translation`,
    { centro_custo_id: Config.centroCustoId }
  );
  return respose.data;
}
