// import React, { useEffect } from 'react';
import AuthenticateServices from "services/AuthenticateServices";
import { create } from "core/api";
import Config from "core/config";
import ConfigLanguage from "core/services/language";

export async function tracking(props) {
  const user = AuthenticateServices.getUser();
  const fingerprint = require("browser-fingerprint")();

  const response = await create(
    `api/${ConfigLanguage.getLanguage(true)
      ? ConfigLanguage.getLanguage(true)
      : "pt-br"
    }/icongressolive/tracking`,
    {
      pessoa_id: user.id,
      local_acesso_ctc_id: props.local_access_ctc_id,
      centro_custo_id: Config.centroCustoId,
      agenda_atividade_id: props.scheduleaActivityId,
      palestrante_centro_custo_id: props.speakerCostCenterId,
      banner_id: props.bannerId,
      trabalho_id: props.workId,
      caex_centro_custo_id: props.caexCostCenterId,
      sala_local_id: props.roomId,
      meta_data: { fingerprint: fingerprint, 'user_agent': window.navigator.userAgent }
    }
  );
  return response;
}

export default function Analytic() {
  return null;
}
