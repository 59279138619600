import React from "react";
import { useStore, useDispatch } from "react-redux";
import { Redirect, Route } from "react-router";
import AuthenticateServices from "services/AuthenticateServices";
import Config from "core/config";
// import { languages } from '_i18n/languages';
// import configLanguage from 'core/services/language';

export default function ProtectedRouter({
  component: Component,
  isPrivate,
  ...rest
}) {
  const store = useStore();
  const responseStore = store.getState();
  const isLogged = responseStore.auth.isLogged;

  const dispatch = useDispatch();
  dispatch({ type: "SHOW_LOADING", loading: true });

  // IsLogged - Redirect Login for Home
  if (rest.login && isLogged)
    return <Route {...rest} render={(props) => <Redirect to={"/"} />} />;

  if (!isPrivate) {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  }

  AuthenticateServices.checkTimeExpired();

  const checkLogin = () => {
    if (localStorage.getItem("toLogin")) {
      return "/";
    }

    return "/login";
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isLogged && isPrivate ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              //pathname: `${configLanguage.getLanguageRouter()}/login`,
              pathname: checkLogin(),
            }}
          />
        )
      }
    />
  );
}
