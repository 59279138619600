import React, { Suspense, useEffect } from 'react';
import Loading from 'components/Loading';
import ProtectedRouter from 'routes/ProtectedRouter';
import Modals from 'modal/Modals';
import configLanguage from 'core/services/language';
import SideBar from 'layouts/SideBar';
import Config from 'core/config';

const Main = React.lazy(() => import('pages/Main'));
const Exhibitor = React.lazy(() => import('pages/Exhibitor'));
const Preview = React.lazy(() => import('pages/Exhibitor/Preview'));
const Welcome = React.lazy(() => import('pages/Welcome'));
const Login = React.lazy(() => import('pages/Login'));
const LoginJwt = React.lazy(() => import('pages/LoginJwt'));
const Room = React.lazy(() => import('pages/Room'));
const Meeting = React.lazy(() => import('pages/Room/meeting'));
const Mobile = React.lazy(() => import('pages/Mobile'));
const MobileMeeting = React.lazy(() => import('pages/MobileMeeting'));
const Networking = React.lazy(() => import('pages/Networking'));
const Version2 = React.lazy(() => import('pages/Version2'));
const ExhibitorLandingPage = React.lazy(() => import('pages/ExhibitorLandingPage'));
const ExhibitorPage = React.lazy(() => import('pages/ExhibitorPage'));
const ExhibitorPageView = React.lazy(() => import('pages/ExhibitorPage/view'));

const NotFound = React.lazy(() => import('pages/NotFound'));

export default function MainRouter(props) {
  if (configLanguage.languages.includes(props.match.params.lang)) {
    configLanguage.setLanguage(props.match.params.lang);
  }
  let routes = [
    {
      path: '/',
      id: 1,
      component: Main,
      exact: true,
    },
    {
      path: '/welcome/:version?',
      id: 3,
      component: Welcome,
      // exact: true,
    },
    {
      path: '/room',
      id: 10,
      component: Room,
      exact: true,
      isPrivate: true,
    },
    {
      path: '/meeting',
      id: 10,
      component: Meeting,
      exact: true,
      isPrivate: true,
    },
    {
      path: '/meeting2',
      id: 11,
      component: Meeting,
      exact: true,
    },
    {
      path: '/exhibitor',
      id: 8,
      component: Exhibitor,
      exact: true,
      isPrivate: true,
    },
    {
      path: '/exhibitor/preview',
      id: 8,
      component: Preview,
      exact: true,
      isPrivate: true,
    },
    {
      path: '/login',
      component: Login,
      exact: true,
    },
    {
      path: '/login-jwt/:jwt',
      component: LoginJwt,
      exact: true,
    },
    {
      path: '/mobile',
      component: Mobile,
      exact: true,
    },
    {
      path: '/mobile-meeting',
      component: MobileMeeting,
      exact: true,
    },
    {
      path: '/lite',
      component: Mobile,
      exact: true,
    },
    {
      path: '/lite-meeting',
      component: MobileMeeting,
      exact: true,
    },
    {
      path: '/landing-page',
      component: Mobile,
      exact: true,
    },
    {
      path: '/networking',
      component: Networking,
      exact: true,
      isPrivate: true,
    },
    {
      path: '/v2',
      component: Version2,
      exact: true,
      isPrivate: false,
    },
    {
      path: '/not-found',
      component: NotFound,
      exact: true,
      isPrivate: false,
    },
    {
      path: '/landing-page-exhibitor',
      component: ExhibitorLandingPage,
      exact: true,
      isPrivate: false,
    },
    {
      path: '/exhibitor-page',
      component: ExhibitorPage,
      exact: true,
      isPrivate: false,
    },
    {
      path: '/exhibitor-page/:id',
      component: ExhibitorPageView,
      exact: true,
      isPrivate: false,
    }
  ];

  routes.map((item) => {
    if (props.match.url !== '/' && configLanguage.languages.includes(props.match.params.lang)) {
      item.path = props.match.url + item.path;
    }
    return item;
  });

  const dontHasMenu = ![
    '/lite',
    '/lite/',
    '/lite-meeting',
    '/lite-meeting/',
    '/mobile',
    '/mobile/',
    '/mobile-meeting',
    '/mobile-meeting/',
    '/landing-page',
    '/landing-page/',
    '/landing-page-exhibitor',
    '/exhibitor-page',
  ].includes(props.location.pathname);

  return (
    <>
      <Suspense fallback={Loading}>
        {dontHasMenu ? <SideBar /> : ''}
        <Modals />
        {routes.map((item, i) => (
          <ProtectedRouter key={i} exact {...item} />
        ))}
      </Suspense>
    </>
  );
}
