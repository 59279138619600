import getTranslation from '../components/Translation'

const translation = {
    translation: {}
}

async function Translation(){
    translation.translation = await getTranslation();
    localStorage.setItem('translation', JSON.stringify(translation));
};

export default Translation;
