import { combineReducers } from "redux";
import application from "./application";
import auth from "./auth";
import client from "./client";
import modals from "./modals";
import utils from "./utils";
import iframe from "./iframe";
import scientificProgramming from "./scientificProgramming";
import landpage from './landpage'
export default combineReducers({
  application,
  auth,
  client,
  modals,
  utils,
  iframe,
  scientificProgramming,
  landpage
});
