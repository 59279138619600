import Config from "core/config";
import ConfigLanguage from "core/services/language";
import { findAll } from "core/api";

const SettingKey = `${Config.client}_setting`;

const SettingServices = {
  get: async () => {
    const response = await findAll(
      `api/${ConfigLanguage.getLanguage(true) ? ConfigLanguage.getLanguage(true) : "pt-br"
      }/icongressolive/setting`,
      {
        centro_custo_id: Config.centroCustoId,
      }
    );
    if (response.data.length > 0) {
      localStorage.setItem(SettingKey, JSON.stringify(response.data[0]));
    }
  },
  getItem: () => {
    return JSON.parse(localStorage.getItem(SettingKey));
  },
};
export default SettingServices;
