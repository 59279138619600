import React from 'react';
import { ContainerCustom } from './styles';
import Menu from '../Menu';
import Loading from 'components/Loading';

export default function SideBar() {
    return (
        <>
            <Loading />
            <ContainerCustom>
                <Menu />
            </ContainerCustom>
        </>
    );
}