import Config from 'core/config';

const initialState = {    
    centroCustoId: '',
    client: '',
};

const authCentroCustoId = `${Config.client}_centroCustoId`;
  
export default (state = initialState, action) => {
    switch (action.type) {
        case "SET_CENTRO_CUSTO_ID":
            localStorage.setItem(authCentroCustoId, action.centroCustoId);
            return { ...state, centroCustoId: action.centroCustoId, client: action.client };

        case "GET_CENTRO_CUSTO_ID":
            return { ...state, centroCustoId: localStorage.getItem(authCentroCustoId) };
            
        case "SET_MAPPER":
            
            localStorage.setItem('mapper', JSON.stringify(action.mapper));
                
            return { ...state, mapper: action.mapper};
        
        case "GET_MAPPER":
            return { ...state, mapper: localStorage.getItem('mapper') };

        default:
            if(localStorage.getItem(authCentroCustoId))
                return {...state, centroCustoId: localStorage.getItem(authCentroCustoId)};
        return state;
    }
};