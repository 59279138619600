// import React from 'react';
import UserServices from 'services/UserServices';

export default function Chats({ chat }) {
    let wdgtzendesk = document.getElementById("ze-snippet");
    let laucher = document.getElementById("laucher");
    let webWidget = document.getElementById("webWidget");
    // let dataproduct = document.querySelector("data-product");

    if (wdgtzendesk) {
        wdgtzendesk.remove();
    }

    if (laucher) {
        laucher.remove();
    }

    if (webWidget) {
        webWidget.remove();
    }

    const script = document.createElement("script");
    script.async = true;
    script.id = "ze-snippet";

    if (chat !== null) {
        script.src = `https://static.zdassets.com/ekr/snippet.js?key=${chat}`;
    }

    document.body.appendChild(script);
    setTimeout(() => {
        if (chat !== null) {
            if (window.zE) {
                window.zESettings = {
                    webWidget: {
                        answerBot: {
                            contactOnlyAfterQuery: true
                        },
                        chat: {
                            hideWhenOffline: false
                        },
                        contactOptions: {
                            enabled: true,
                            chatLabelOnline: { '*': 'Live Chat' },
                            chatLabelOffline: { '*': 'Chat is unavailable' }
                        },
                        launcher: {
                            chatLabel: {
                                '*': 'Chat now'
                            }, mobile: {
                                labelVisible: true
                            }
                        }
                    }
                };
                window.zE('webWidget', 'identify', {
                    name: UserServices.getUser().name,
                    email: UserServices.getUser().email,
                    // organization: ''
                });
            }
        }
    }, 1000);

    return '';

}